.detail-item {
    margin-bottom: 15px;
}

.detail-item-title {
    display: inline-block;
    padding-bottom: 8px;
    font-size: var(--ioc_font_size_0);
    font-weight: var(--ioc_font_weight_bold);
}

.inline {
    display: inline-block;
    padding-left: 4px;
}

.max-number-configs-input {
    display: inline-block;
    margin-left: 8px;
    width: 48px;
}

.max-number-configs-input div {
    width: inherit;
}
