.option-title {
    display: flex;
    margin-bottom: 10px;
    font-weight: var(--ioc_font_weight_bold);
    font-size: var(--ioc_font_size_0);
}

.option-item {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
}

.option-item-title {
    vertical-align: middle;
    display: inline-block;
    padding-right: 10px;
    flex: 1;
}

.editor {
    border: 1px solid var(--ioc_light_theme_grey_3);
    border-radius: 3px;
    background: white;
    font-family: "Fira code", "Fira Mono", monospace;
    font-size: 13px;
}

.editor textarea {
  outline: 0;
}

.editor-focused {
    outline: 0;
    box-shadow: var(--ioc_focus_box_shadow);
}

.option-section {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 3px solid var(--ioc_light_theme_grey_5);
}
