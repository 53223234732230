.table-container {
    max-width: 1000px;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.cell {
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
}

.icon-text-center {
    display: flex;
    align-items: center;

    span {
        padding-left: 5px;
    }
}

.trash-button {
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.delete-gameserver-tooltip {
    max-width: 380px;
    white-space: normal;
    padding: 20px;
}

.delete-gameserver-tooltip-button {
    margin-top: 10px;
}

.delete-warning {
    display: block;
    padding: 15px 0;
}
