.actions {
    display: flex;
}

.table-tree-views {
    border-bottom: none;
    background: #ffffff;
}

.no-allocations {
    background: #ffffff;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 15px;
}

.more-options-button {
    padding-left: 10px;
    padding-right: 10px;
}

.force-tooltip {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px 0 5px;
}

.icon-text-center {
    display: flex;
    align-items: center;

    span {
        padding-left: 5px;
    }
}

.cell {
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
}

.template-value-modal {
    width: 1100px;
    max-height: 800px;
    overflow-y: auto;
}


.template-value-link {
    cursor: pointer;
    text-decoration: underline;
    font-weight:600;
    padding-left: 5px;
}

.overflow-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.copy-button {
    cursor: pointer;
    padding-right:10px;
}

.status-dot {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.status-info {
    display: inline-block;
    padding-left: 5px;
    vertical-align: -1px;
}

.multiline-tooltip {
    max-width: 350px;
    padding: 10px;
}

.danger {
    color: var(--ioc_severity_danger);
}

.table-title {
    font-weight: bold;
}

.table-header {
    font-weight: bold;
}

.metadata-tag {
    padding: 3px;
    display: inline-block;
}
