.detail-item {
    margin-bottom: 15px;
}

.detail-item-title {
    padding-bottom: 8px;
    align-items: center;
    display: flex;
    font-size: var(--ioc_font_size_0);
    font-weight: var(--ioc_font_weight_bold);
}

.detail-item-loader {
    padding-left: 4px;
}

.detail-item-template-name {
    display: inline-block;
    width: calc(100% - 160px);
    flex: 2
}

.detail-item-template-version {
    display: inline-block;
    width: 128px;
    margin-left: 32px;
}

.detail-item-description {
    font-size: 13px;
    padding-left: 10px;
}

.loading-item {
    display: flex;
    align-items: center;
}

.detail-loader {
    display: inline-block;
}

.detail-loading-text {
    display: inline-block;
    font-size: 13px;
    padding-left: 10px;
}

.max-number-configs-title {
    margin-left: 32px;
    font-weight: var(--ioc_font_weight_bold);
}

.max-number-configs-input {
    display: inline-block;
    margin-left: 8px;
    width: 48px;
}

.max-number-configs-input div {
    width: inherit;
}
