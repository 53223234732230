.notifications-wrapper {
  position: absolute;
  right: 10px;
  top: 64px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
}

.notifications-wrapper > * {
  margin-bottom: 10px;
}
