.background {
  background-image: url("../../../statics/images/background.png");
  background-size: cover;
}

.container {
  display: flex;
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
  height: 100vh;
}

.box {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Muli;
  padding: 40px;
}

.title {
  font-family: Muli;
  font-size: 45px;
}

.auth-button {
  margin-top: 30px;
  margin-bottom: 20px;
}

.sign-up-link {
  color: #587df3;
}
