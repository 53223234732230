.cluster-modal {
    width: 700px;
}

.buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.link-and-force-buttons {
    display: flex;
    align-items: center;
}

.force-tooltip {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2px 32px 0 0;
}

.force-button-contents {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.detail-item {
    display: flex;
    padding: 8px;
    align-items: center;
}

.detail-header {
    display: flex;
    padding: 32px 8px 8px;
    align-items: center;
}

.detail-item-title {
    font-weight: 600;
    font-size: 13px;
    vertical-align: middle;
    display: inline-block;
}

.detail-item-value {
    display: inline-block;
    flex: 1;
}

.error-container {
    max-width: 500px;
}

.error-message {
    padding-top: 20px;
}
