.deploy {
    width: 700px;
    margin-bottom: 32px;
    max-height: 80vh;
    overflow-y: scroll;
}

.detail-item {
    margin-bottom: 15px;
}

.detail-item-title {
    padding-bottom: 8px;
    align-items: center;
    display: flex;
    font-size: var(--ioc_font_size_0);
    font-weight: var(--ioc_font_weight_bold);
}

.detail-item-loader {
    padding-left: 4px;
}

.deploy-radios {
    display: flex;
    align-items: center;
}
