.actions {
    display: flex;
}

.table {
    overflow: auto;
    height: 300px;
    width: 100%;
}

.cell {
    overflow: auto;
    padding: 3px 5px;
}

.cell-timestamp {
    overflow: hidden;
}

.status-info {
    display: inline-block;
    padding-left: 5px;
    vertical-align: -1px;
}

.multiline-tooltip {
    max-width: 350px;
    padding: 10px;
}
