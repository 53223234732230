.sign-in-button {
  display: flex;
  align-items: center;
}

.icon-wrapper {
  padding: 5px;
  background-color: #333333;
  color: white;
  height: 40px;
  width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.label {
  display: flex;
  color: #333333;
  padding: 0 60px;
  font-family: Muli;
  font-size: 16px;
}

.button {
  border: none;
  background-color: #e3e3e3;
  padding: 0px;
}

.logout {
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
}
