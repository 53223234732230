.cluster-details-modal {
    width: 750px;
    max-height: 800px;
    overflow-y: auto;
}

.overflow-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.copy-button {
    cursor: pointer;
    padding-right:10px;
}
