.deploy {
    width: 450px;
    margin-bottom: 32px;
}

.detail-item {
    margin-bottom: 15px;
}

.detail-item-title {
    padding-bottom: 8px;
    font-size: var(--ioc_font_size_0);
    font-weight: var(--ioc_font_weight_bold);
}

.detail-item-value {
    padding-bottom: 8px;
    padding-left: 8px;
    font-size: var(--ioc_font_size_0);
}

.error-container {
    max-width: 500px;
}

.error-message {
    padding-top: 20px;
}
