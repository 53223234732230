.links {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 1em;
}

.link-item {
  margin: 0 .5em;
}

.dropdown {
  background-color: var(--ioc_dark_theme_grey_0);
  height: 3em;
  display: flex;
}
