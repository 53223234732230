// tslint:disable

import * as request from "superagent";
import {
    SuperAgentStatic,
    SuperAgentRequest,
    Response
} from "superagent";

export type RequestHeaders = {
    [header: string]: string;
}
export type RequestHeadersHandler = (headers: RequestHeaders) => RequestHeaders;

export type ConfigureAgentHandler = (agent: SuperAgentStatic) => SuperAgentStatic;

export type ConfigureRequestHandler = (agent: SuperAgentRequest) => SuperAgentRequest;

export type CallbackHandler = (err: any, res ? : request.Response) => void;

export type AllocationAllocationCategory = "UNSPECIFIED" | "GAME_DEPLOYMENT";

export type ChartMuseum_ListChartVersionsByNameResponse = Array < Helm_ChartVersion >
;

export type ChartMuseum_ListChartVersionsByNameVersionResponse = {} & {
    [key: string]: Helm_ChartVersion;
};

export type ChartMuseum_ListChartVersionsResponse = {} & {
    [key: string]: Helm_ChartVersion;
};

export type ClusterStatus = "UNKNOWN" | "VALID" | "INVALID";

export type Helm_ChartVersion = {
    'apiVersion' ? : string;
    'appVersion' ? : string;
    'description' ? : string;
    'name' ? : string;
    'type' ? : string;
    'urls' ? : Array < string >
    ;
    'version' ? : string;
} & {
    [key: string]: any;
};

export type ReleaseCategory = "UNSPECIFIED" | "GAME_DEPLOYMENT";

export type pbChart = {
    'metadata' ? : pbChartMetadata;
    'name': string;
    'values': {} & {
        [key: string]: string;
    };
    'version': string;
} & {
    [key: string]: any;
};

export type pbChartMetadata = {
    'description' ? : string;
    'version' ? : string;
} & {
    [key: string]: any;
};

export type pbCluster = {
    'alias' ? : string;
    'ca_cert': string;
    'ca_cert_hash' ? : string;
    'domain' ? : string;
    'host': string;
    'id': pbClusterId;
    'region' ? : string;
    'status' ? : ClusterStatus;
    'token': string;
    'token_hash' ? : string;
} & {
    [key: string]: any;
};

export type pbClusterId = {
    'name': string;
} & {
    [key: string]: any;
};

export type pbCreateClusterRequest = {
    'cluster': pbCluster;
    'validate_cluster_exists' ? : boolean;
} & {
    [key: string]: any;
};

export type pbCreateClusterResponse = {
    'cluster': pbCluster;
} & {
    [key: string]: any;
};

export type pbCreateReleaseConfigRequest = {
    'release_config': pbReleaseConfig;
} & {
    [key: string]: any;
};

export type pbCreateReleaseConfigResponse = {
    'release_config': pbReleaseConfig;
} & {
    [key: string]: any;
};

export type pbCreateReleaseRequest = {
    'release': pbRelease;
} & {
    [key: string]: any;
};

export type pbCreateReleaseResponse = {
    'release': pbRelease;
} & {
    [key: string]: any;
};

export type pbDeleteClusterResponse = {} & {
    [key: string]: any;
};

export type pbDeleteGameserverResponse = {} & {
    [key: string]: any;
};

export type pbDeleteReleaseConfigResponse = {} & {
    [key: string]: any;
};

export type pbDeleteReleaseResponse = {} & {
    [key: string]: any;
};

export type pbFleet = {
    'cluster_alias' ? : string;
    'deployed_at' ? : string;
    'helm_release' ? : string;
    'id': pbFleetId;
    'status' ? : pbFleetStatus;
} & {
    [key: string]: any;
};

export type pbFleetAutoscalerStatus = {
    'last_scaled' ? : string;
    'max_replicas' ? : number;
    'min_replicas' ? : number;
} & {
    [key: string]: any;
};

export type pbFleetId = {
    'cluster_id': pbClusterId;
    'name': string;
    'namespace': string;
} & {
    [key: string]: any;
};

export type pbFleetStatus = {
    'allocated_replicas' ? : number;
    'autoscaler_status' ? : pbFleetAutoscalerStatus;
    'ready_replicas' ? : number;
    'replicas' ? : number;
    'reserved_replicas' ? : number;
} & {
    [key: string]: any;
};

export type pbGameserver = {
    'cluster_alias' ? : string;
    'created_at' ? : string;
    'fleet_name' ? : string;
    'helm_release' ? : string;
    'id': pbGameserverId;
    'status' ? : pbGameserverStatus;
} & {
    [key: string]: any;
};

export type pbGameserverId = {
    'cluster_id': pbClusterId;
    'name': string;
    'namespace': string;
} & {
    [key: string]: any;
};

export type pbGameserverPlayerStatus = {
    'capacity' ? : string;
    'count' ? : string;
} & {
    [key: string]: any;
};

export type pbGameserverStatus = {
    'players' ? : pbGameserverPlayerStatus;
    'state' ? : string;
} & {
    [key: string]: any;
};

export type pbGetChartResponse = {
    'chart' ? : pbChart;
} & {
    [key: string]: any;
};

export type pbGetFleetInfoResponse = {
    'fleet' ? : pbFleet;
} & {
    [key: string]: any;
};

export type pbLinks = {
    'lobby' ? : string;
    'logs' ? : string;
    'metrics' ? : string;
} & {
    [key: string]: any;
};

export type pbListClustersResponse = {
    'clusters': Array < pbCluster >
    ;
} & {
    [key: string]: any;
};

export type pbListFleetsResponse = {
    'fleets' ? : Array < pbFleet >
    ;
} & {
    [key: string]: any;
};

export type pbListGameserversInFleetResponse = {
    'gameservers' ? : Array < pbGameserver >
    ;
} & {
    [key: string]: any;
};

export type pbListReleaseConfigsResponse = {
    'release_configs' ? : Array < pbReleaseConfig >
    ;
} & {
    [key: string]: any;
};

export type pbListReleasesResponse = {
    'releases' ? : Array < pbRelease >
    ;
} & {
    [key: string]: any;
};

export type pbRelease = {
    'artifact_bucket_prefix' ? : string;
    'category' ? : ReleaseCategory;
    'chart': pbChart;
    'cluster_alias' ? : string;
    'creator' ? : string;
    'id': pbReleaseId;
    'last_deployed' ? : string;
    'links' ? : pbLinks;
    'status' ? : string;
    'status_description' ? : string;
} & {
    [key: string]: any;
};

export type pbReleaseConfig = {
    'chart_name': string;
    'chart_values': {} & {
        [key: string]: string;
    };
    'chart_version_constraint' ? : string;
    'created_at' ? : string;
    'id': string;
} & {
    [key: string]: any;
};

export type pbReleaseId = {
    'cluster_id': pbClusterId;
    'name': string;
} & {
    [key: string]: any;
};

export type pbUpgradeReleaseResponse = {} & {
    [key: string]: any;
};

export type protobufAny = {
    'type_url' ? : string;
    'value' ? : string;
} & {
    [key: string]: any;
};

export type protobufNullValue = "NULL_VALUE";

export type runtimeError = {
    'code' ? : number;
    'details' ? : Array < protobufAny >
    ;
    'error' ? : string;
    'message' ? : string;
} & {
    [key: string]: any;
};

export type v1Allocation = {
    'artifact_bucket_prefix' ? : string;
    'category' ? : AllocationAllocationCategory;
    'cluster' ? : v1ClusterOverview;
    'creator' ? : string;
    'id': v1AllocationId;
    'last_deployed' ? : string;
    'links' ? : v1Links;
    'metadata' ? : {} & {
        [key: string]: string;
    };
    'status' ? : string;
    'status_description' ? : string;
    'template': v1Template;
} & {
    [key: string]: any;
};

export type v1AllocationConfig = {
    'created_at' ? : string;
    'id': string;
    'template_name': string;
    'template_values': {} & {
        [key: string]: any;
    };
    'template_version_constraint': string;
} & {
    [key: string]: any;
};

export type v1AllocationId = {
    'cluster_id': v1ClusterId;
    'name': string;
} & {
    [key: string]: any;
};

export type v1Chart = {
    'metadata': v1ChartMetadata;
    'name': string;
    'values': {} & {
        [key: string]: any;
    };
} & {
    [key: string]: any;
};

export type v1ChartMetadata = {
    'description' ? : string;
    'version': string;
} & {
    [key: string]: any;
};

export type v1ClusterDetails = {
    'alias' ? : string;
    'ca_cert': string;
    'ca_cert_hash' ? : string;
    'domain' ? : string;
    'host': string;
    'id': v1ClusterId;
    'region' ? : string;
    'status' ? : v1ClusterStatus;
    'token': string;
    'token_hash' ? : string;
} & {
    [key: string]: any;
};

export type v1ClusterId = {
    'name': string;
} & {
    [key: string]: any;
};

export type v1ClusterOverview = {
    'alias': string;
    'domain': string;
    'id': v1ClusterId;
    'region': string;
} & {
    [key: string]: any;
};

export type v1ClusterStatus = "UNKNOWN" | "REACHABLE" | "UNREACHABLE";

export type v1CreateAllocationConfigResponse = {
    'allocation_config': v1AllocationConfig;
} & {
    [key: string]: any;
};

export type v1CreateAllocationResponse = {
    'allocation': v1Allocation;
} & {
    [key: string]: any;
};

export type v1CreateReleaseConfigResponse = {
    'release_config': v1ReleaseConfig;
} & {
    [key: string]: any;
};

export type v1CreateReleaseResponse = {
    'release': v1Release;
} & {
    [key: string]: any;
};

export type v1DeleteAllocationConfigResponse = {} & {
    [key: string]: any;
};

export type v1DeleteAllocationResponse = {} & {
    [key: string]: any;
};

export type v1DeleteGameserverResponse = {} & {
    [key: string]: any;
};

export type v1DeleteReleaseConfigResponse = {} & {
    [key: string]: any;
};

export type v1DeleteReleaseResponse = {} & {
    [key: string]: any;
};

export type v1Fleet = {
    'cluster' ? : v1ClusterOverview;
    'deployed_at' ? : string;
    'helm_release' ? : string;
    'id': v1FleetId;
    'status' ? : v1FleetStatus;
} & {
    [key: string]: any;
};

export type v1FleetAutoscalerStatus = {
    'last_scaled' ? : string;
    'max_replicas' ? : number;
    'min_replicas' ? : number;
} & {
    [key: string]: any;
};

export type v1FleetId = {
    'cluster_id': v1ClusterId;
    'name': string;
    'namespace': string;
} & {
    [key: string]: any;
};

export type v1FleetStatus = {
    'allocated_replicas' ? : number;
    'autoscaler_status' ? : v1FleetAutoscalerStatus;
    'ready_replicas' ? : number;
    'replicas' ? : number;
    'reserved_replicas' ? : number;
} & {
    [key: string]: any;
};

export type v1Gameserver = {
    'created_at' ? : string;
    'fleet' ? : v1FleetId;
    'helm_release' ? : string;
    'id': v1GameserverId;
    'status' ? : v1GameserverStatus;
} & {
    [key: string]: any;
};

export type v1GameserverId = {
    'cluster_id': v1ClusterId;
    'name': string;
    'namespace': string;
} & {
    [key: string]: any;
};

export type v1GameserverPlayerStatus = {
    'capacity' ? : string;
    'count' ? : string;
} & {
    [key: string]: any;
};

export type v1GameserverStatus = {
    'players' ? : v1GameserverPlayerStatus;
    'state' ? : string;
} & {
    [key: string]: any;
};

export type v1GetChartResponse = {
    'chart' ? : v1Chart;
} & {
    [key: string]: any;
};

export type v1GetClusterDetailsResponse = {
    'cluster': v1ClusterDetails;
} & {
    [key: string]: any;
};

export type v1GetFleetInfoResponse = {
    'fleet' ? : v1Fleet;
} & {
    [key: string]: any;
};

export type v1GetTemplateResponse = {
    'template' ? : v1Template;
} & {
    [key: string]: any;
};

export type v1LinkClusterRequest = {
    'cluster': v1ClusterDetails;
    'validate_cluster_exists' ? : boolean;
} & {
    [key: string]: any;
};

export type v1LinkClusterResponse = {
    'cluster': v1ClusterDetails;
} & {
    [key: string]: any;
};

export type v1Links = {
    'lobby' ? : string;
    'logs' ? : string;
    'metrics' ? : string;
} & {
    [key: string]: any;
};

export type v1ListAllocationConfigsResponse = {
    'allocation_configs' ? : Array < v1AllocationConfig >
    ;
} & {
    [key: string]: any;
};

export type v1ListAllocationsResponse = {
    'allocations' ? : Array < v1Allocation >
    ;
} & {
    [key: string]: any;
};

export type v1ListClustersResponse = {
    'clusters': Array < v1ClusterOverview >
    ;
} & {
    [key: string]: any;
};

export type v1ListFleetsResponse = {
    'fleets' ? : Array < v1Fleet >
    ;
} & {
    [key: string]: any;
};

export type v1ListGameserversInFleetResponse = {
    'gameservers' ? : Array < v1Gameserver >
    ;
} & {
    [key: string]: any;
};

export type v1ListReleaseConfigsResponse = {
    'release_configs' ? : Array < v1ReleaseConfig >
    ;
} & {
    [key: string]: any;
};

export type v1ListReleasesResponse = {
    'releases' ? : Array < v1Release >
    ;
} & {
    [key: string]: any;
};

export type v1Release = {
    'artifact_bucket_prefix' ? : string;
    'category' ? : v1ReleaseCategory;
    'chart': v1Chart;
    'cluster' ? : v1ClusterOverview;
    'creator' ? : string;
    'id': v1ReleaseId;
    'last_deployed' ? : string;
    'links' ? : v1Links;
    'status' ? : string;
    'status_description' ? : string;
} & {
    [key: string]: any;
};

export type v1ReleaseCategory = "UNSPECIFIED" | "GAME_DEPLOYMENT";

export type v1ReleaseConfig = {
    'chart_name': string;
    'chart_values': {} & {
        [key: string]: any;
    };
    'chart_version_constraint': string;
    'created_at' ? : string;
    'id': string;
} & {
    [key: string]: any;
};

export type v1ReleaseId = {
    'cluster_id': v1ClusterId;
    'name': string;
} & {
    [key: string]: any;
};

export type v1Template = {
    'metadata': v1TemplateMetadata;
    'name': string;
    'values': {} & {
        [key: string]: any;
    };
} & {
    [key: string]: any;
};

export type v1TemplateMetadata = {
    'description' ? : string;
    'version': string;
} & {
    [key: string]: any;
};

export type v1UnlinkClusterResponse = {} & {
    [key: string]: any;
};

export type v1UpgradeAllocationResponse = {
    'allocation' ? : v1Allocation;
} & {
    [key: string]: any;
};

export type v1UpgradeReleaseResponse = {
    'release' ? : v1Release;
} & {
    [key: string]: any;
};

export type Response_ChartMuseumV0_ListChartVersions_403 = {} & {
    [key: string]: any;
};

export type Response_ChartMuseumV0_ListChartVersions_404 = {} & {
    [key: string]: any;
};

export type Response_ChartMuseumV0_UploadChart_201 = {
    'saved' ? : boolean;
} & {
    [key: string]: any;
};

export type Response_ChartMuseumV0_UploadChart_409 = {} & {
    [key: string]: any;
};

export type Response_ChartMuseumV0_ListChartVersionsByName_403 = {} & {
    [key: string]: any;
};

export type Response_ChartMuseumV0_ListChartVersionsByName_404 = {} & {
    [key: string]: any;
};

export type Response_ChartMuseumV0_GetChartVersionsByName_403 = {} & {
    [key: string]: any;
};

export type Response_ChartMuseumV0_GetChartVersionsByName_404 = {} & {
    [key: string]: any;
};

export type Response_chartMuseumV0_DeleteChartVersionByName_200 = {
    'deleted' ? : boolean;
} & {
    [key: string]: any;
};

export type Response_chartMuseumV0_DeleteChartVersionByName_403 = {} & {
    [key: string]: any;
};

export type Response_chartMuseumV0_DeleteChartVersionByName_404 = {} & {
    [key: string]: any;
};

export type Response_ChartsServiceV0_GetChart_403 = {} & {
    [key: string]: any;
};

export type Response_ChartsServiceV0_GetChart_404 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV0_ListClusters_403 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV0_ListClusters_404 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV0_CreateCluster_403 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV0_CreateCluster_404 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV0_DeleteCluster_403 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV0_DeleteCluster_404 = {} & {
    [key: string]: any;
};

export type Response_FleetsServiceV0_GetFleetInfo_403 = {} & {
    [key: string]: any;
};

export type Response_FleetsServiceV0_GetFleetInfo_404 = {} & {
    [key: string]: any;
};

export type Response_GameserversServiceV0_ListGameserversInFleet_403 = {} & {
    [key: string]: any;
};

export type Response_GameserversServiceV0_ListGameserversInFleet_404 = {} & {
    [key: string]: any;
};

export type Response_GameserversServiceV0_DeleteGameserver_403 = {} & {
    [key: string]: any;
};

export type Response_GameserversServiceV0_DeleteGameserver_404 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV0_UpgradeRelease_403 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV0_UpgradeRelease_404 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV0_CreateRelease_403 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV0_CreateRelease_404 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV0_DeleteRelease_403 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV0_DeleteRelease_404 = {} & {
    [key: string]: any;
};

export type Response_FleetsServiceV0_ListFleets_403 = {} & {
    [key: string]: any;
};

export type Response_FleetsServiceV0_ListFleets_404 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV0_ListReleaseConfigs_403 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV0_ListReleaseConfigs_404 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV0_CreateReleaseConfig_403 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV0_CreateReleaseConfig_404 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV0_DeleteReleaseConfig_403 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV0_DeleteReleaseConfig_404 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV0_ListReleases_403 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV0_ListReleases_404 = {} & {
    [key: string]: any;
};

export type Response_AllocationConfigsServiceV1_ListAllocationConfigs_403 = {} & {
    [key: string]: any;
};

export type Response_AllocationConfigsServiceV1_ListAllocationConfigs_404 = {} & {
    [key: string]: any;
};

export type Response_AllocationConfigsServiceV1_CreateAllocationConfig_403 = {} & {
    [key: string]: any;
};

export type Response_AllocationConfigsServiceV1_CreateAllocationConfig_404 = {} & {
    [key: string]: any;
};

export type Response_AllocationConfigsServiceV1_DeleteAllocationConfig_403 = {} & {
    [key: string]: any;
};

export type Response_AllocationConfigsServiceV1_DeleteAllocationConfig_404 = {} & {
    [key: string]: any;
};

export type Response_AllocationsServiceV1_ListAllocations_403 = {} & {
    [key: string]: any;
};

export type Response_AllocationsServiceV1_ListAllocations_404 = {} & {
    [key: string]: any;
};

export type Response_ChartsServiceV1_GetChart_403 = {} & {
    [key: string]: any;
};

export type Response_ChartsServiceV1_GetChart_404 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV1_ListClusters_403 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV1_ListClusters_404 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV1_LinkCluster_403 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV1_LinkCluster_404 = {} & {
    [key: string]: any;
};

export type Response_AllocationsServiceV1_CreateAllocation_403 = {} & {
    [key: string]: any;
};

export type Response_AllocationsServiceV1_CreateAllocation_404 = {} & {
    [key: string]: any;
};

export type Response_AllocationsServiceV1_DeleteAllocation_403 = {} & {
    [key: string]: any;
};

export type Response_AllocationsServiceV1_DeleteAllocation_404 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV1_GetClusterDetails_403 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV1_GetClusterDetails_404 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV1_UnlinkCluster_403 = {} & {
    [key: string]: any;
};

export type Response_ClustersServiceV1_UnlinkCluster_404 = {} & {
    [key: string]: any;
};

export type Response_FleetsServiceV1_GetFleetInfo_403 = {} & {
    [key: string]: any;
};

export type Response_FleetsServiceV1_GetFleetInfo_404 = {} & {
    [key: string]: any;
};

export type Response_GameserversServiceV1_ListGameserversInFleet_403 = {} & {
    [key: string]: any;
};

export type Response_GameserversServiceV1_ListGameserversInFleet_404 = {} & {
    [key: string]: any;
};

export type Response_GameserversServiceV1_DeleteGameserver_403 = {} & {
    [key: string]: any;
};

export type Response_GameserversServiceV1_DeleteGameserver_404 = {} & {
    [key: string]: any;
};

export type Response_AllocationsServiceV1_UpgradeAllocation_403 = {} & {
    [key: string]: any;
};

export type Response_AllocationsServiceV1_UpgradeAllocation_404 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV1_UpgradeRelease_403 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV1_UpgradeRelease_404 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV1_CreateRelease_403 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV1_CreateRelease_404 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV1_DeleteRelease_403 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV1_DeleteRelease_404 = {} & {
    [key: string]: any;
};

export type Response_FleetsServiceV1_ListFleets_403 = {} & {
    [key: string]: any;
};

export type Response_FleetsServiceV1_ListFleets_404 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV1_ListReleaseConfigs_403 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV1_ListReleaseConfigs_404 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV1_CreateReleaseConfig_403 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV1_CreateReleaseConfig_404 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV1_DeleteReleaseConfig_403 = {} & {
    [key: string]: any;
};

export type Response_ReleaseConfigsServiceV1_DeleteReleaseConfig_404 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV1_ListReleases_403 = {} & {
    [key: string]: any;
};

export type Response_ReleasesServiceV1_ListReleases_404 = {} & {
    [key: string]: any;
};

export type Response_TemplatesServiceV1_GetTemplate_403 = {} & {
    [key: string]: any;
};

export type Response_TemplatesServiceV1_GetTemplate_404 = {} & {
    [key: string]: any;
};

export type Logger = {
    log: (line: string) => any
};

export interface ResponseWithBody < S extends number, T > extends Response {
    status: S;
    body: T;
}

export type QueryParameters = {
    [param: string]: any
};

export interface CommonRequestOptions {
    $queryParameters ? : QueryParameters;
    $domain ? : string;
    $path ? : string | ((path: string) => string);
    $retries ? : number; // number of retries; see: https://github.com/visionmedia/superagent/blob/master/docs/index.md#retrying-requests
    $timeout ? : number; // request timeout in milliseconds; see: https://github.com/visionmedia/superagent/blob/master/docs/index.md#timeouts
    $deadline ? : number; // request deadline in milliseconds; see: https://github.com/visionmedia/superagent/blob/master/docs/index.md#timeouts
}

/**
 * 
 * @class SwaggerTestClient
 * @param {(string)} [domainOrOptions] - The project domain.
 */
export class SwaggerTestClient {

    private domain: string = "";
    private errorHandlers: CallbackHandler[] = [];
    private requestHeadersHandler ? : RequestHeadersHandler;
    private configureAgentHandler ? : ConfigureAgentHandler;
    private configureRequestHandler ? : ConfigureRequestHandler;

    constructor(domain ? : string, private logger ? : Logger) {
        if (domain) {
            this.domain = domain;
        }
    }

    getDomain() {
        return this.domain;
    }

    addErrorHandler(handler: CallbackHandler) {
        this.errorHandlers.push(handler);
    }

    setRequestHeadersHandler(handler: RequestHeadersHandler) {
        this.requestHeadersHandler = handler;
    }

    setConfigureAgentHandler(handler: ConfigureAgentHandler) {
        this.configureAgentHandler = handler;
    }

    setConfigureRequestHandler(handler: ConfigureRequestHandler) {
        this.configureRequestHandler = handler;
    }

    private request(method: string, url: string, body: any, headers: RequestHeaders, queryParameters: QueryParameters, form: any, reject: CallbackHandler, resolve: CallbackHandler, opts: CommonRequestOptions) {
        if (this.logger) {
            this.logger.log(`Call ${method} ${url}`);
        }

        const agent = this.configureAgentHandler ?
            this.configureAgentHandler(request.default) :
            request.default;

        let req = agent(method, url);
        if (this.configureRequestHandler) {
            req = this.configureRequestHandler(req);
        }

        req = req.query(queryParameters);

        if (this.requestHeadersHandler) {
            headers = this.requestHeadersHandler({
                ...headers
            });
        }

        req.set(headers);

        if (body) {
            req.send(body);

            if (typeof(body) === 'object' && !(body.constructor.name === 'Buffer')) {
                headers['content-type'] = 'application/json';
            }
        }

        if (Object.keys(form).length > 0) {
            req.type('form');
            req.send(form);
        }

        if (opts.$retries && opts.$retries > 0) {
            req.retry(opts.$retries);
        }

        if (opts.$timeout && opts.$timeout > 0 || opts.$deadline && opts.$deadline > 0) {
            req.timeout({
                deadline: opts.$deadline,
                response: opts.$timeout
            });
        }

        req.end((error, response) => {
            // an error will also be emitted for a 4xx and 5xx status code
            // the error object will then have error.status and error.response fields
            // see superagent error handling: https://github.com/visionmedia/superagent/blob/master/docs/index.md#error-handling
            if (error) {
                reject(error);
                this.errorHandlers.forEach(handler => handler(error));
            } else {
                resolve(response);
            }
        });
    }

    private convertParameterCollectionFormat < T > (param: T, collectionFormat: string | undefined): T | string {
        if (Array.isArray(param) && param.length >= 2) {
            switch (collectionFormat) {
                case "csv":
                    return param.join(",");
                case "ssv":
                    return param.join(" ");
                case "tsv":
                    return param.join("\t");
                case "pipes":
                    return param.join("|");
                default:
                    return param;
            }
        }

        return param;
    }

    ChartMuseumV0_ListChartVersionsURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/chartmuseum/charts';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * List all charts available from Chart Museum.
     * @method
     * @name SwaggerTestClient#ChartMuseumV0_ListChartVersions
     */
    ChartMuseumV0_ListChartVersions(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, ChartMuseum_ListChartVersionsResponse > | ResponseWithBody < 403, Response_ChartMuseumV0_ListChartVersions_403 > | ResponseWithBody < 404, Response_ChartMuseumV0_ListChartVersions_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/chartmuseum/charts';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ChartMuseumV0_UploadChartURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/chartmuseum/charts';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Upload a new Helm Chart archive
     * @method
     * @name SwaggerTestClient#ChartMuseumV0_UploadChart
     */
    ChartMuseumV0_UploadChart(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 201, Response_ChartMuseumV0_UploadChart_201 > | ResponseWithBody < 409, Response_ChartMuseumV0_UploadChart_409 >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/chartmuseum/charts';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            form = queryParameters;
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ChartMuseumV0_ListChartVersionsByNameURL(parameters: {
        'chartName': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/chartmuseum/charts/{chart_name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{chart_name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['chartName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * List version of a particular chart, by name
     * @method
     * @name SwaggerTestClient#ChartMuseumV0_ListChartVersionsByName
     * @param {string} chartName - Name of the chart to list
     */
    ChartMuseumV0_ListChartVersionsByName(parameters: {
        'chartName': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, ChartMuseum_ListChartVersionsByNameResponse > | ResponseWithBody < 403, Response_ChartMuseumV0_ListChartVersionsByName_403 > | ResponseWithBody < 404, Response_ChartMuseumV0_ListChartVersionsByName_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/chartmuseum/charts/{chart_name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{chart_name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['chartName'],
                    ''
                ).toString())}`
            );

            if (parameters['chartName'] === undefined) {
                reject(new Error('Missing required  parameter: chartName'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ChartMuseumV0_GetChartVersionsByNameURL(parameters: {
        'chartName': string,
        'version': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/chartmuseum/charts/{chart_name}/{version}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{chart_name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['chartName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{version}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['version'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get chart by name and version
     * @method
     * @name SwaggerTestClient#ChartMuseumV0_GetChartVersionsByName
     * @param {string} chartName - Name of the chart to list
     * @param {string} version - Version of the chart
     */
    ChartMuseumV0_GetChartVersionsByName(parameters: {
        'chartName': string,
        'version': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Helm_ChartVersion > | ResponseWithBody < 403, Response_ChartMuseumV0_GetChartVersionsByName_403 > | ResponseWithBody < 404, Response_ChartMuseumV0_GetChartVersionsByName_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/chartmuseum/charts/{chart_name}/{version}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{chart_name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['chartName'],
                    ''
                ).toString())}`
            );

            if (parameters['chartName'] === undefined) {
                reject(new Error('Missing required  parameter: chartName'));
                return;
            }

            path = path.replace(
                '{version}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['version'],
                    ''
                ).toString())}`
            );

            if (parameters['version'] === undefined) {
                reject(new Error('Missing required  parameter: version'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    chartMuseumV0_DeleteChartVersionByNameURL(parameters: {
        'chartName': string,
        'version': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/chartmuseum/charts/{chart_name}/{version}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{chart_name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['chartName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{version}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['version'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Delete a helm chart by name and version
     * @method
     * @name SwaggerTestClient#chartMuseumV0_DeleteChartVersionByName
     * @param {string} chartName - Name of the chart to list
     * @param {string} version - Version of the chart
     */
    chartMuseumV0_DeleteChartVersionByName(parameters: {
        'chartName': string,
        'version': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_chartMuseumV0_DeleteChartVersionByName_200 > | ResponseWithBody < 403, Response_chartMuseumV0_DeleteChartVersionByName_403 > | ResponseWithBody < 404, Response_chartMuseumV0_DeleteChartVersionByName_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/chartmuseum/charts/{chart_name}/{version}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{chart_name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['chartName'],
                    ''
                ).toString())}`
            );

            if (parameters['chartName'] === undefined) {
                reject(new Error('Missing required  parameter: chartName'));
                return;
            }

            path = path.replace(
                '{version}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['version'],
                    ''
                ).toString())}`
            );

            if (parameters['version'] === undefined) {
                reject(new Error('Missing required  parameter: version'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ChartsServiceV0_GetChartURL(parameters: {
        'name': string,
        'version': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/charts/{name}/{version}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['name'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{version}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['version'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get details of a specific Helm Chart.
     * @method
     * @name SwaggerTestClient#ChartsServiceV0_GetChart
     * @param {string} name - 
     * @param {string} version - 
     */
    ChartsServiceV0_GetChart(parameters: {
        'name': string,
        'version': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, pbGetChartResponse > | ResponseWithBody < 403, Response_ChartsServiceV0_GetChart_403 > | ResponseWithBody < 404, Response_ChartsServiceV0_GetChart_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/charts/{name}/{version}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['name'],
                    ''
                ).toString())}`
            );

            if (parameters['name'] === undefined) {
                reject(new Error('Missing required  parameter: name'));
                return;
            }

            path = path.replace(
                '{version}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['version'],
                    ''
                ).toString())}`
            );

            if (parameters['version'] === undefined) {
                reject(new Error('Missing required  parameter: version'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleasesServiceV0_UpgradeReleaseURL(parameters: {
        'idClusterIdName': string,
        'idName': string,
        'body': pbChart,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/clusters/{id.cluster_id.name}/releases/{id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['idClusterIdName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['idName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Upgrade an existing release. Rolls back changes made in case of failed upgrade.
     * @method
     * @name SwaggerTestClient#ReleasesServiceV0_UpgradeRelease
     * @param {string} idClusterIdName - name is the GCP name of this cluster
     * @param {string} idName - 
     * @param {} body - Reuse the last release's values and merge in any overrides
     */
    ReleasesServiceV0_UpgradeRelease(parameters: {
        'idClusterIdName': string,
        'idName': string,
        'body': pbChart,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, pbUpgradeReleaseResponse > | ResponseWithBody < 403, Response_ReleasesServiceV0_UpgradeRelease_403 > | ResponseWithBody < 404, Response_ReleasesServiceV0_UpgradeRelease_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/clusters/{id.cluster_id.name}/releases/{id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['idClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['idClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: idClusterIdName'));
                return;
            }

            path = path.replace(
                '{id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['idName'],
                    ''
                ).toString())}`
            );

            if (parameters['idName'] === undefined) {
                reject(new Error('Missing required  parameter: idName'));
                return;
            }

            if (parameters['body'] !== undefined) {
                body = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleasesServiceV0_CreateReleaseURL(parameters: {
        'releaseIdClusterIdName': string,
        'body': pbCreateReleaseRequest,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/clusters/{release.id.cluster_id.name}/releases';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{release.id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['releaseIdClusterIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Create a new release. The release will be immediately installed to the cluster. Successfully creating a release does not guarantee the release deployed successfully to the game cluster.
     * @method
     * @name SwaggerTestClient#ReleasesServiceV0_CreateRelease
     * @param {string} releaseIdClusterIdName - name is the GCP name of this cluster
     * @param {} body - 
     */
    ReleasesServiceV0_CreateRelease(parameters: {
        'releaseIdClusterIdName': string,
        'body': pbCreateReleaseRequest,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, pbCreateReleaseResponse > | ResponseWithBody < 403, Response_ReleasesServiceV0_CreateRelease_403 > | ResponseWithBody < 404, Response_ReleasesServiceV0_CreateRelease_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/clusters/{release.id.cluster_id.name}/releases';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{release.id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['releaseIdClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['releaseIdClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: releaseIdClusterIdName'));
                return;
            }

            if (parameters['body'] !== undefined) {
                body = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            form = queryParameters;
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleasesServiceV0_DeleteReleaseURL(parameters: {
        'releaseIdClusterIdName': string,
        'releaseIdName': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/clusters/{release_id.cluster_id.name}/releases/{release_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{release_id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['releaseIdClusterIdName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{release_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['releaseIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Delete a release from a cluster.
     * @method
     * @name SwaggerTestClient#ReleasesServiceV0_DeleteRelease
     * @param {string} releaseIdClusterIdName - name is the GCP name of this cluster
     * @param {string} releaseIdName - 
     */
    ReleasesServiceV0_DeleteRelease(parameters: {
        'releaseIdClusterIdName': string,
        'releaseIdName': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, pbDeleteReleaseResponse > | ResponseWithBody < 403, Response_ReleasesServiceV0_DeleteRelease_403 > | ResponseWithBody < 404, Response_ReleasesServiceV0_DeleteRelease_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/clusters/{release_id.cluster_id.name}/releases/{release_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{release_id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['releaseIdClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['releaseIdClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: releaseIdClusterIdName'));
                return;
            }

            path = path.replace(
                '{release_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['releaseIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['releaseIdName'] === undefined) {
                reject(new Error('Missing required  parameter: releaseIdName'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleaseConfigsServiceV0_ListReleaseConfigsURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/release-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * List all release configs.
     * @method
     * @name SwaggerTestClient#ReleaseConfigsServiceV0_ListReleaseConfigs
     */
    ReleaseConfigsServiceV0_ListReleaseConfigs(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, pbListReleaseConfigsResponse > | ResponseWithBody < 403, Response_ReleaseConfigsServiceV0_ListReleaseConfigs_403 > | ResponseWithBody < 404, Response_ReleaseConfigsServiceV0_ListReleaseConfigs_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/release-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleaseConfigsServiceV0_CreateReleaseConfigURL(parameters: {
        'body': pbCreateReleaseConfigRequest,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/release-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Create a new release config.
     * @method
     * @name SwaggerTestClient#ReleaseConfigsServiceV0_CreateReleaseConfig
     * @param {} body - 
     */
    ReleaseConfigsServiceV0_CreateReleaseConfig(parameters: {
        'body': pbCreateReleaseConfigRequest,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, pbCreateReleaseConfigResponse > | ResponseWithBody < 403, Response_ReleaseConfigsServiceV0_CreateReleaseConfig_403 > | ResponseWithBody < 404, Response_ReleaseConfigsServiceV0_CreateReleaseConfig_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/release-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters['body'] !== undefined) {
                body = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            form = queryParameters;
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleaseConfigsServiceV0_DeleteReleaseConfigURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/release-configs/{id}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{id}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['id'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Delete a release config.
     * @method
     * @name SwaggerTestClient#ReleaseConfigsServiceV0_DeleteReleaseConfig
     * @param {string} id - 
     */
    ReleaseConfigsServiceV0_DeleteReleaseConfig(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, pbDeleteReleaseConfigResponse > | ResponseWithBody < 403, Response_ReleaseConfigsServiceV0_DeleteReleaseConfig_403 > | ResponseWithBody < 404, Response_ReleaseConfigsServiceV0_DeleteReleaseConfig_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/release-configs/{id}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{id}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                ).toString())}`
            );

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleasesServiceV0_ListReleasesURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/releases';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * List releases in all clusters.
     * @method
     * @name SwaggerTestClient#ReleasesServiceV0_ListReleases
     */
    ReleasesServiceV0_ListReleases(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, pbListReleasesResponse > | ResponseWithBody < 403, Response_ReleasesServiceV0_ListReleases_403 > | ResponseWithBody < 404, Response_ReleasesServiceV0_ListReleases_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v0/releases';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    AllocationConfigsServiceV1_ListAllocationConfigsURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/allocation-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * List all allocation configs.
     * @method
     * @name SwaggerTestClient#AllocationConfigsServiceV1_ListAllocationConfigs
     */
    AllocationConfigsServiceV1_ListAllocationConfigs(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, v1ListAllocationConfigsResponse > | ResponseWithBody < 403, Response_AllocationConfigsServiceV1_ListAllocationConfigs_403 > | ResponseWithBody < 404, Response_AllocationConfigsServiceV1_ListAllocationConfigs_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/allocation-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    AllocationConfigsServiceV1_CreateAllocationConfigURL(parameters: {
        'body': v1AllocationConfig,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/allocation-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Create a new allocation config.
     * @method
     * @name SwaggerTestClient#AllocationConfigsServiceV1_CreateAllocationConfig
     * @param {} body - allocation_config is the allocation config to create.
     */
    AllocationConfigsServiceV1_CreateAllocationConfig(parameters: {
        'body': v1AllocationConfig,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1CreateAllocationConfigResponse > | ResponseWithBody < 403, Response_AllocationConfigsServiceV1_CreateAllocationConfig_403 > | ResponseWithBody < 404, Response_AllocationConfigsServiceV1_CreateAllocationConfig_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/allocation-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters['body'] !== undefined) {
                body = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            form = queryParameters;
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    AllocationConfigsServiceV1_DeleteAllocationConfigURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/allocation-configs/{id}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{id}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['id'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Delete a allocation config.
     * @method
     * @name SwaggerTestClient#AllocationConfigsServiceV1_DeleteAllocationConfig
     * @param {string} id - id is the unique id of the allocation config to delete.
     */
    AllocationConfigsServiceV1_DeleteAllocationConfig(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1DeleteAllocationConfigResponse > | ResponseWithBody < 403, Response_AllocationConfigsServiceV1_DeleteAllocationConfig_403 > | ResponseWithBody < 404, Response_AllocationConfigsServiceV1_DeleteAllocationConfig_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/allocation-configs/{id}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{id}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                ).toString())}`
            );

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    AllocationsServiceV1_ListAllocationsURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/allocations';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * List allocations in all clusters.
     * @method
     * @name SwaggerTestClient#AllocationsServiceV1_ListAllocations
     */
    AllocationsServiceV1_ListAllocations(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, v1ListAllocationsResponse > | ResponseWithBody < 403, Response_AllocationsServiceV1_ListAllocations_403 > | ResponseWithBody < 404, Response_AllocationsServiceV1_ListAllocations_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/allocations';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ChartsServiceV1_GetChartURL(parameters: {
        'name': string,
        'version': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/charts/{name}/{version}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['name'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{version}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['version'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get details of a specific Helm Chart.
     * @method
     * @name SwaggerTestClient#ChartsServiceV1_GetChart
     * @param {string} name - name is the chart name.
     * @param {string} version - version is the chart version to retrieve.
     */
    ChartsServiceV1_GetChart(parameters: {
        'name': string,
        'version': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1GetChartResponse > | ResponseWithBody < 403, Response_ChartsServiceV1_GetChart_403 > | ResponseWithBody < 404, Response_ChartsServiceV1_GetChart_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/charts/{name}/{version}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['name'],
                    ''
                ).toString())}`
            );

            if (parameters['name'] === undefined) {
                reject(new Error('Missing required  parameter: name'));
                return;
            }

            path = path.replace(
                '{version}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['version'],
                    ''
                ).toString())}`
            );

            if (parameters['version'] === undefined) {
                reject(new Error('Missing required  parameter: version'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ClustersServiceV1_ListClustersURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Lists all registered clusters.
     * @method
     * @name SwaggerTestClient#ClustersServiceV1_ListClusters
     */
    ClustersServiceV1_ListClusters(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, v1ListClustersResponse > | ResponseWithBody < 403, Response_ClustersServiceV1_ListClusters_403 > | ResponseWithBody < 404, Response_ClustersServiceV1_ListClusters_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ClustersServiceV1_LinkClusterURL(parameters: {
        'body': v1LinkClusterRequest,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Links a new kubernetes cluster.
     * @method
     * @name SwaggerTestClient#ClustersServiceV1_LinkCluster
     * @param {} body - 
     */
    ClustersServiceV1_LinkCluster(parameters: {
        'body': v1LinkClusterRequest,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1LinkClusterResponse > | ResponseWithBody < 403, Response_ClustersServiceV1_LinkCluster_403 > | ResponseWithBody < 404, Response_ClustersServiceV1_LinkCluster_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters['body'] !== undefined) {
                body = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            form = queryParameters;
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    AllocationsServiceV1_CreateAllocationURL(parameters: {
        'allocationIdClusterIdName': string,
        'body': v1Allocation,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{allocation.id.cluster_id.name}/allocations';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{allocation.id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['allocationIdClusterIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Create a new allocation. The allocation will be immediately installed to the cluster. Successfully creating a allocation does not guarantee the allocation deployed successfully to the game cluster.
     * @method
     * @name SwaggerTestClient#AllocationsServiceV1_CreateAllocation
     * @param {string} allocationIdClusterIdName - name is the unique name of this cluster
     * @param {} body - allocation is the allocation to create.
     */
    AllocationsServiceV1_CreateAllocation(parameters: {
        'allocationIdClusterIdName': string,
        'body': v1Allocation,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1CreateAllocationResponse > | ResponseWithBody < 403, Response_AllocationsServiceV1_CreateAllocation_403 > | ResponseWithBody < 404, Response_AllocationsServiceV1_CreateAllocation_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{allocation.id.cluster_id.name}/allocations';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{allocation.id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['allocationIdClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['allocationIdClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: allocationIdClusterIdName'));
                return;
            }

            if (parameters['body'] !== undefined) {
                body = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            form = queryParameters;
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    AllocationsServiceV1_DeleteAllocationURL(parameters: {
        'allocationIdClusterIdName': string,
        'allocationIdName': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{allocation_id.cluster_id.name}/allocations/{allocation_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{allocation_id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['allocationIdClusterIdName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{allocation_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['allocationIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Delete a allocation from a cluster.
     * @method
     * @name SwaggerTestClient#AllocationsServiceV1_DeleteAllocation
     * @param {string} allocationIdClusterIdName - name is the unique name of this cluster
     * @param {string} allocationIdName - name is the name of the allocation.
     */
    AllocationsServiceV1_DeleteAllocation(parameters: {
        'allocationIdClusterIdName': string,
        'allocationIdName': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1DeleteAllocationResponse > | ResponseWithBody < 403, Response_AllocationsServiceV1_DeleteAllocation_403 > | ResponseWithBody < 404, Response_AllocationsServiceV1_DeleteAllocation_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{allocation_id.cluster_id.name}/allocations/{allocation_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{allocation_id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['allocationIdClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['allocationIdClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: allocationIdClusterIdName'));
                return;
            }

            path = path.replace(
                '{allocation_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['allocationIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['allocationIdName'] === undefined) {
                reject(new Error('Missing required  parameter: allocationIdName'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ClustersServiceV1_GetClusterDetailsURL(parameters: {
        'clusterIdName': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{cluster_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['clusterIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get the details of a registered cluster.
     * @method
     * @name SwaggerTestClient#ClustersServiceV1_GetClusterDetails
     * @param {string} clusterIdName - name is the unique name of this cluster
     */
    ClustersServiceV1_GetClusterDetails(parameters: {
        'clusterIdName': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1GetClusterDetailsResponse > | ResponseWithBody < 403, Response_ClustersServiceV1_GetClusterDetails_403 > | ResponseWithBody < 404, Response_ClustersServiceV1_GetClusterDetails_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{cluster_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['clusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['clusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: clusterIdName'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ClustersServiceV1_UnlinkClusterURL(parameters: {
        'clusterIdName': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{cluster_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['clusterIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Unlinks a cluster from the GSS. The underlying k8s cluster and its releases will not be deleted.
     * @method
     * @name SwaggerTestClient#ClustersServiceV1_UnlinkCluster
     * @param {string} clusterIdName - name is the unique name of this cluster
     */
    ClustersServiceV1_UnlinkCluster(parameters: {
        'clusterIdName': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1UnlinkClusterResponse > | ResponseWithBody < 403, Response_ClustersServiceV1_UnlinkCluster_403 > | ResponseWithBody < 404, Response_ClustersServiceV1_UnlinkCluster_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{cluster_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['clusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['clusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: clusterIdName'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    FleetsServiceV1_GetFleetInfoURL(parameters: {
        'fleetIdClusterIdName': string,
        'fleetIdNamespace': string,
        'fleetIdName': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{fleet_id.cluster_id.name}/namespaces/{fleet_id.namespace}/fleets/{fleet_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{fleet_id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['fleetIdClusterIdName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{fleet_id.namespace}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['fleetIdNamespace'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{fleet_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['fleetIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Gets information for a given Fleet in the supplied cluster and namespace
     * @method
     * @name SwaggerTestClient#FleetsServiceV1_GetFleetInfo
     * @param {string} fleetIdClusterIdName - name is the unique name of this cluster
     * @param {string} fleetIdNamespace - namespace is the kubernetes namespace for the Fleet.
     * @param {string} fleetIdName - name is the Agones Fleet resource name.
     */
    FleetsServiceV1_GetFleetInfo(parameters: {
        'fleetIdClusterIdName': string,
        'fleetIdNamespace': string,
        'fleetIdName': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1GetFleetInfoResponse > | ResponseWithBody < 403, Response_FleetsServiceV1_GetFleetInfo_403 > | ResponseWithBody < 404, Response_FleetsServiceV1_GetFleetInfo_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{fleet_id.cluster_id.name}/namespaces/{fleet_id.namespace}/fleets/{fleet_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{fleet_id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['fleetIdClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['fleetIdClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: fleetIdClusterIdName'));
                return;
            }

            path = path.replace(
                '{fleet_id.namespace}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['fleetIdNamespace'],
                    ''
                ).toString())}`
            );

            if (parameters['fleetIdNamespace'] === undefined) {
                reject(new Error('Missing required  parameter: fleetIdNamespace'));
                return;
            }

            path = path.replace(
                '{fleet_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['fleetIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['fleetIdName'] === undefined) {
                reject(new Error('Missing required  parameter: fleetIdName'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    GameserversServiceV1_ListGameserversInFleetURL(parameters: {
        'fleetIdClusterIdName': string,
        'fleetIdNamespace': string,
        'fleetIdName': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{fleet_id.cluster_id.name}/namespaces/{fleet_id.namespace}/fleets/{fleet_id.name}/gameservers';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{fleet_id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['fleetIdClusterIdName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{fleet_id.namespace}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['fleetIdNamespace'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{fleet_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['fleetIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * List all gameservers in a given fleet
     * @method
     * @name SwaggerTestClient#GameserversServiceV1_ListGameserversInFleet
     * @param {string} fleetIdClusterIdName - name is the unique name of this cluster
     * @param {string} fleetIdNamespace - namespace is the kubernetes namespace for the Fleet.
     * @param {string} fleetIdName - name is the Agones Fleet resource name.
     */
    GameserversServiceV1_ListGameserversInFleet(parameters: {
        'fleetIdClusterIdName': string,
        'fleetIdNamespace': string,
        'fleetIdName': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1ListGameserversInFleetResponse > | ResponseWithBody < 403, Response_GameserversServiceV1_ListGameserversInFleet_403 > | ResponseWithBody < 404, Response_GameserversServiceV1_ListGameserversInFleet_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{fleet_id.cluster_id.name}/namespaces/{fleet_id.namespace}/fleets/{fleet_id.name}/gameservers';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{fleet_id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['fleetIdClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['fleetIdClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: fleetIdClusterIdName'));
                return;
            }

            path = path.replace(
                '{fleet_id.namespace}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['fleetIdNamespace'],
                    ''
                ).toString())}`
            );

            if (parameters['fleetIdNamespace'] === undefined) {
                reject(new Error('Missing required  parameter: fleetIdNamespace'));
                return;
            }

            path = path.replace(
                '{fleet_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['fleetIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['fleetIdName'] === undefined) {
                reject(new Error('Missing required  parameter: fleetIdName'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    GameserversServiceV1_DeleteGameserverURL(parameters: {
        'gameserverIdClusterIdName': string,
        'gameserverIdNamespace': string,
        'gameserverIdName': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{gameserver_id.cluster_id.name}/namespaces/{gameserver_id.namespace}/gameservers/{gameserver_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{gameserver_id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['gameserverIdClusterIdName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{gameserver_id.namespace}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['gameserverIdNamespace'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{gameserver_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['gameserverIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Stop a given GameServer
     * @method
     * @name SwaggerTestClient#GameserversServiceV1_DeleteGameserver
     * @param {string} gameserverIdClusterIdName - name is the unique name of this cluster
     * @param {string} gameserverIdNamespace - namespace is the kubernetes namespace for the GameServer.
     * @param {string} gameserverIdName - name is the Agones GameServer name.
     */
    GameserversServiceV1_DeleteGameserver(parameters: {
        'gameserverIdClusterIdName': string,
        'gameserverIdNamespace': string,
        'gameserverIdName': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1DeleteGameserverResponse > | ResponseWithBody < 403, Response_GameserversServiceV1_DeleteGameserver_403 > | ResponseWithBody < 404, Response_GameserversServiceV1_DeleteGameserver_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{gameserver_id.cluster_id.name}/namespaces/{gameserver_id.namespace}/gameservers/{gameserver_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{gameserver_id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['gameserverIdClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['gameserverIdClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: gameserverIdClusterIdName'));
                return;
            }

            path = path.replace(
                '{gameserver_id.namespace}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['gameserverIdNamespace'],
                    ''
                ).toString())}`
            );

            if (parameters['gameserverIdNamespace'] === undefined) {
                reject(new Error('Missing required  parameter: gameserverIdNamespace'));
                return;
            }

            path = path.replace(
                '{gameserver_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['gameserverIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['gameserverIdName'] === undefined) {
                reject(new Error('Missing required  parameter: gameserverIdName'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    AllocationsServiceV1_UpgradeAllocationURL(parameters: {
        'idClusterIdName': string,
        'idName': string,
        'body': v1Template,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{id.cluster_id.name}/allocations/{id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['idClusterIdName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['idName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Upgrade an existing allocation. Rolls back changes made in case of failed upgrade.
     * @method
     * @name SwaggerTestClient#AllocationsServiceV1_UpgradeAllocation
     * @param {string} idClusterIdName - name is the unique name of this cluster
     * @param {string} idName - name is the name of the allocation.
     * @param {} body - template contains the template to use for the upgrade. Reuses the last allocation's values and merges in any overrides.
     */
    AllocationsServiceV1_UpgradeAllocation(parameters: {
        'idClusterIdName': string,
        'idName': string,
        'body': v1Template,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1UpgradeAllocationResponse > | ResponseWithBody < 403, Response_AllocationsServiceV1_UpgradeAllocation_403 > | ResponseWithBody < 404, Response_AllocationsServiceV1_UpgradeAllocation_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{id.cluster_id.name}/allocations/{id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['idClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['idClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: idClusterIdName'));
                return;
            }

            path = path.replace(
                '{id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['idName'],
                    ''
                ).toString())}`
            );

            if (parameters['idName'] === undefined) {
                reject(new Error('Missing required  parameter: idName'));
                return;
            }

            if (parameters['body'] !== undefined) {
                body = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleasesServiceV1_UpgradeReleaseURL(parameters: {
        'idClusterIdName': string,
        'idName': string,
        'body': v1Chart,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{id.cluster_id.name}/releases/{id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['idClusterIdName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['idName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Upgrade an existing release. Rolls back changes made in case of failed upgrade.
     * @method
     * @name SwaggerTestClient#ReleasesServiceV1_UpgradeRelease
     * @param {string} idClusterIdName - name is the unique name of this cluster
     * @param {string} idName - name is the name of the release.
     * @param {} body - chart contains the chart to use for the upgrade. Reuses the last release's values and merges in any overrides.
     */
    ReleasesServiceV1_UpgradeRelease(parameters: {
        'idClusterIdName': string,
        'idName': string,
        'body': v1Chart,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1UpgradeReleaseResponse > | ResponseWithBody < 403, Response_ReleasesServiceV1_UpgradeRelease_403 > | ResponseWithBody < 404, Response_ReleasesServiceV1_UpgradeRelease_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{id.cluster_id.name}/releases/{id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['idClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['idClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: idClusterIdName'));
                return;
            }

            path = path.replace(
                '{id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['idName'],
                    ''
                ).toString())}`
            );

            if (parameters['idName'] === undefined) {
                reject(new Error('Missing required  parameter: idName'));
                return;
            }

            if (parameters['body'] !== undefined) {
                body = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleasesServiceV1_CreateReleaseURL(parameters: {
        'releaseIdClusterIdName': string,
        'body': v1Release,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{release.id.cluster_id.name}/releases';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{release.id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['releaseIdClusterIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Create a new release. The release will be immediately installed to the cluster. Successfully creating a release does not guarantee the release deployed successfully to the game cluster.
     * @method
     * @name SwaggerTestClient#ReleasesServiceV1_CreateRelease
     * @param {string} releaseIdClusterIdName - name is the unique name of this cluster
     * @param {} body - release is the release to create.
     */
    ReleasesServiceV1_CreateRelease(parameters: {
        'releaseIdClusterIdName': string,
        'body': v1Release,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1CreateReleaseResponse > | ResponseWithBody < 403, Response_ReleasesServiceV1_CreateRelease_403 > | ResponseWithBody < 404, Response_ReleasesServiceV1_CreateRelease_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{release.id.cluster_id.name}/releases';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{release.id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['releaseIdClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['releaseIdClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: releaseIdClusterIdName'));
                return;
            }

            if (parameters['body'] !== undefined) {
                body = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            form = queryParameters;
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleasesServiceV1_DeleteReleaseURL(parameters: {
        'releaseIdClusterIdName': string,
        'releaseIdName': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{release_id.cluster_id.name}/releases/{release_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{release_id.cluster_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['releaseIdClusterIdName'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{release_id.name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['releaseIdName'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Delete a release from a cluster.
     * @method
     * @name SwaggerTestClient#ReleasesServiceV1_DeleteRelease
     * @param {string} releaseIdClusterIdName - name is the unique name of this cluster
     * @param {string} releaseIdName - name is the name of the release.
     */
    ReleasesServiceV1_DeleteRelease(parameters: {
        'releaseIdClusterIdName': string,
        'releaseIdName': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1DeleteReleaseResponse > | ResponseWithBody < 403, Response_ReleasesServiceV1_DeleteRelease_403 > | ResponseWithBody < 404, Response_ReleasesServiceV1_DeleteRelease_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/clusters/{release_id.cluster_id.name}/releases/{release_id.name}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{release_id.cluster_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['releaseIdClusterIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['releaseIdClusterIdName'] === undefined) {
                reject(new Error('Missing required  parameter: releaseIdClusterIdName'));
                return;
            }

            path = path.replace(
                '{release_id.name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['releaseIdName'],
                    ''
                ).toString())}`
            );

            if (parameters['releaseIdName'] === undefined) {
                reject(new Error('Missing required  parameter: releaseIdName'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    FleetsServiceV1_ListFleetsURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/fleets';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Lists all deployed Fleets across all clusters.
     * @method
     * @name SwaggerTestClient#FleetsServiceV1_ListFleets
     */
    FleetsServiceV1_ListFleets(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, v1ListFleetsResponse > | ResponseWithBody < 403, Response_FleetsServiceV1_ListFleets_403 > | ResponseWithBody < 404, Response_FleetsServiceV1_ListFleets_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/fleets';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleaseConfigsServiceV1_ListReleaseConfigsURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/release-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * List all release configs.
     * @method
     * @name SwaggerTestClient#ReleaseConfigsServiceV1_ListReleaseConfigs
     */
    ReleaseConfigsServiceV1_ListReleaseConfigs(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, v1ListReleaseConfigsResponse > | ResponseWithBody < 403, Response_ReleaseConfigsServiceV1_ListReleaseConfigs_403 > | ResponseWithBody < 404, Response_ReleaseConfigsServiceV1_ListReleaseConfigs_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/release-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleaseConfigsServiceV1_CreateReleaseConfigURL(parameters: {
        'body': v1ReleaseConfig,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/release-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Create a new release config.
     * @method
     * @name SwaggerTestClient#ReleaseConfigsServiceV1_CreateReleaseConfig
     * @param {} body - release_config is the release config to create.
     */
    ReleaseConfigsServiceV1_CreateReleaseConfig(parameters: {
        'body': v1ReleaseConfig,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1CreateReleaseConfigResponse > | ResponseWithBody < 403, Response_ReleaseConfigsServiceV1_CreateReleaseConfig_403 > | ResponseWithBody < 404, Response_ReleaseConfigsServiceV1_CreateReleaseConfig_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/release-configs';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters['body'] !== undefined) {
                body = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            form = queryParameters;
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleaseConfigsServiceV1_DeleteReleaseConfigURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/release-configs/{id}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{id}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['id'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Delete a release config.
     * @method
     * @name SwaggerTestClient#ReleaseConfigsServiceV1_DeleteReleaseConfig
     * @param {string} id - id is the unique id of the release config to delete.
     */
    ReleaseConfigsServiceV1_DeleteReleaseConfig(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1DeleteReleaseConfigResponse > | ResponseWithBody < 403, Response_ReleaseConfigsServiceV1_DeleteReleaseConfig_403 > | ResponseWithBody < 404, Response_ReleaseConfigsServiceV1_DeleteReleaseConfig_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/release-configs/{id}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{id}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                ).toString())}`
            );

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    ReleasesServiceV1_ListReleasesURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/releases';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * List releases in all clusters.
     * @method
     * @name SwaggerTestClient#ReleasesServiceV1_ListReleases
     */
    ReleasesServiceV1_ListReleases(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, v1ListReleasesResponse > | ResponseWithBody < 403, Response_ReleasesServiceV1_ListReleases_403 > | ResponseWithBody < 404, Response_ReleasesServiceV1_ListReleases_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/releases';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    TemplatesServiceV1_GetTemplateURL(parameters: {
        'name': string,
        'version': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/templates/{name}/{version}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        path = path.replace(
            '{name}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['name'],
                        ''
                    ).toString())}`
        );

        path = path.replace(
            '{version}',
            `${encodeURIComponent(this.convertParameterCollectionFormat(
                        parameters['version'],
                        ''
                    ).toString())}`
        );

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get details of a specific Helm Template.
     * @method
     * @name SwaggerTestClient#TemplatesServiceV1_GetTemplate
     * @param {string} name - name is the template name.
     * @param {string} version - version is the template version to retrieve.
     */
    TemplatesServiceV1_GetTemplate(parameters: {
        'name': string,
        'version': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, v1GetTemplateResponse > | ResponseWithBody < 403, Response_TemplatesServiceV1_GetTemplate_403 > | ResponseWithBody < 404, Response_TemplatesServiceV1_GetTemplate_404 > | ResponseWithBody < number, runtimeError >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/orchestration/v1/templates/{name}/{version}';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            path = path.replace(
                '{name}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['name'],
                    ''
                ).toString())}`
            );

            if (parameters['name'] === undefined) {
                reject(new Error('Missing required  parameter: name'));
                return;
            }

            path = path.replace(
                '{version}',
                `${encodeURIComponent(this.convertParameterCollectionFormat(
                    parameters['version'],
                    ''
                ).toString())}`
            );

            if (parameters['version'] === undefined) {
                reject(new Error('Missing required  parameter: version'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

}

export default SwaggerTestClient;