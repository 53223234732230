.component {
    margin-bottom: 1.5em;
    align-items: center;
    color: var(--ioc_light_theme_grey_6);
    display: flex;
    font-size: 1em;

    a {
        color: inherit;
        opacity: .5;
        text-decoration: none;

        &:hover {
           opacity: 1;
           color: var(--ioc_blue);
        }
    }

    .active {
        border-bottom-color: var(--ioc_blue);
        color: var(--ioc_blue);
        opacity: 1;
    }

    a:not(:last-child) {
        margin-right: 35px;
     }
}

.link {
    border-bottom: 2px solid transparent;
    padding: 4px 0;
    white-space: nowrap;
}
