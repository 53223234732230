.table-title {
    font-weight: bold;
}

.cell {
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
}

.actions {
    display: flex;
}

.more-options-button {
    padding-left: 10px;
    padding-right: 10px;
}

.icon-text-center {
    display: flex;
    align-items: center;

    span {
        padding-left: 5px;
    }
}
