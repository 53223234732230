.multiline-tooltip {
    max-width: 350px;
    padding: 10px;
}

.actions {
    display: flex;
}

.more-options-button {
    padding-left: 10px;
    padding-right: 10px;
}

.name-cell {
    padding-top: 10px;
    padding-bottom: 10px;
}

.cell {
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
}

.danger {
    color: var(--ioc_severity_danger);
}

.icon-text-center {
    display: flex;
    align-items: center;

    span {
        padding-left: 5px;
    }
}

.cluster-details-modal {
    width: 750px;
    max-height: 800px;
    overflow-y: auto;
}

.overflow-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.copy-button {
    cursor: pointer;
    padding-right:10px;
}

.table-title {
    font-weight: bold;
}
